<template>
  <gf-content title="Pack & Bundles" subtitle="List of Pack / Bundles">
    <template #toolbar>
      <el-input style="width: 250px" class="mr-4" v-model="search"></el-input>
      <gf-button type="primary" @click="addPack">
        <template slot="icon">
          <span class="svg-icon svg-icon-white svg-icon-sm">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/>
              </g>
            </svg>
          </span>
        </template>
        Add Pack
      </gf-button>
    </template>
    <gf-table :data="packs" @row-click="readPack">
      <el-table-column label="SKU" min-width="180px" prop="sku">
      </el-table-column>
      <el-table-column label="Name" min-width="170px" prop="name">
        <template slot-scope="slot">
          <span>{{ slot.row.name }} (Pack of {{ slot.row.pack_size }})</span>
        </template>
      </el-table-column>
      <el-table-column label="Pack Size" min-width="170px" prop="pack_size">
      </el-table-column>
      <el-table-column label="Buy Price" min-width="130px">
        <template slot-scope="scope">
          {{ (scope.row.prices.find(r => r.price_list_id === 1)) ? (scope.row.prices.find(r => r.price_list_id === 1)).value : null }}
        </template>
      </el-table-column>
      <el-table-column label="Wholesale price" min-width="130px">
        <template slot-scope="scope">
          {{ (scope.row.prices.find(r => r.price_list_id === 2)) ? (scope.row.prices.find(r => r.price_list_id === 2)).value : null }}
        </template>
      </el-table-column>
      <el-table-column label="Retail Price" min-width="130px">
        <template slot-scope="scope">
          {{ (scope.row.prices.find(r => r.price_list_id === 3)) ? (scope.row.prices.find(r => r.price_list_id === 3)).value : null }}
        </template>
      </el-table-column>
    </gf-table>
    <template #footer>
      <gf-pagination :pagination="pagination" @change="getPacks"></gf-pagination>
    </template>
  </gf-content>
</template>

<script>
// Services
import ProductVariantService from '@/services/v1/ProductVariant'

// Lodash
import { debounce } from 'lodash'

export default {
  data () {
    return {
      // ui
      loading: false,
      search: '',

      // main
      variant: {
        name: null,
        product: {
          name: null
        }
      },
      packs: [],

      filter: {
        'pv.variant_id': {
          '=': this.$route.params.vid
        },
        'pv.is_pack': {
          '=': true
        }
      },

      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      }
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      this.getPacks()
    }, 400)
  },
  methods: {
    addPack () {
      this.$router.push({ name: 'product-variant-pack-create' })
    },
    readPack (row) {
      this.$router.push({ name: 'product-variant-pack-view', params: { id: this.$route.params.id, vid: this.$route.params.vid, pid: row.id } })
    },

    // Fetch
    async getPacks () {
      try {
        this.loading = true

        const pvService = new ProductVariantService(this.$route.params.id)
        const response = await pvService.list(this.pagination.page, null, this.filter)
        this.packs = response.data.rows

        if (response.data.count === 0 && response.data.rows.length !== 0) {
        } else this.pagination.total = response.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },
    async getVariant () {
      try {
        const pvService = new ProductVariantService(this.$route.params.id, this.$route.params.vid)
        const response = await pvService.get()
        this.variant.name = response.data.name
        this.variant.product.name = response.data.product.name
      } catch (error) {
        this.$Error(error)
      }
    }
  },
  async mounted () {
    this.$emit('update:active', 2)
    await this.getPacks()
    await this.getVariant()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Inventory' },
      { title: 'Products' },
      { title: this.variant.product.name },
      { title: 'Variants' },
      { title: this.variant.name },
      { title: 'Pack & Bundles' }
    ])
  }
}
</script>
